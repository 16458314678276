import { Component } from '@angular/core';
import {FormControl, FormGroup, FormGroupDirective, FormsModule, NgForm, ReactiveFormsModule, Validators} from "@angular/forms";
import {ErrorStateMatcher} from "@angular/material/core";
import {Subscription} from "rxjs";
import {LoginService} from "../../services/login.service";
import {Router} from "@angular/router";
import {AuthService} from "../../services/auth.service";
import {StorageService} from "../../services/storage.service";
import {AUTH_ACCESS_TOKEN_KEY, AUTH_REFRESH_TOKEN_KEY} from "../../http-interceptors/token-caching.interceptor";
import {CookieService} from "ngx-cookie-service";
import { SalonStaff, StaffRole } from '../../models/store';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { HttpClientModule } from '@angular/common/http';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [MatCardModule, 
            FormsModule, 
            ReactiveFormsModule,
            CommonModule, 
            MatIconModule, 
            // HttpClientModule
          ],
  providers: [
    HttpClientModule
  ],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  isLoginByUsername = true
  showPwd = false
  username = ""
  password = ""
  phoneNumber: string = ""
  sub!: Subscription

  isFormSubmitted = false;
  loginForm = new FormGroup({
    username: new FormControl<string>("", [Validators.required]),
    password: new FormControl<string>("", Validators.required)
  })
  loginStatus: boolean | null = null

  constructor(
              private loginService: LoginService,
              // private userService: UserService,
              private authService: AuthService,
              private storageService: StorageService,
              private cookieService: CookieService,
              private router: Router) {
  }

  ngOnInit(): void {
    const user = this.storageService.getStaff();
    if (this.cookieService.get(AUTH_REFRESH_TOKEN_KEY).length > 0 && user != null) {
      if (this.cookieService.get(AUTH_ACCESS_TOKEN_KEY).length > 0) {
        this.router.navigate(['/customers']).then()
      } else {
        this.authService.refreshToken().subscribe(
          authenticationResp => {
            this.router.navigate(['/customers']).then()
          }
        )
      }
      console.log("okeeeee")
    }
  }

  login(state: 'username' | 'phone') {
    if(state === 'username') {
      this.isFormSubmitted = true;
      if (this.loginForm.valid) {
        this.loginStatus = null
        this.authService.logIn(this.username, this.password).subscribe({
          next: authenticationResp => {
            if (authenticationResp) {
              this.loginStatus = true
              // Save data to local storage
              this.storageService.saveStaff(authenticationResp.staff)
              this.router.navigate(['/customers']).then()
            } else {
              this.loginStatus = false
            }
          }
        })
      }
    } else {
      if(this.phoneNumber === '') return;
    }
  }

  navigateToDashboard(staff: SalonStaff) {
    if(staff.roles.includes(StaffRole.SALON_MANAGER)) {
      this.router.navigate([''])
    } else {
      this.router.navigate(['/staff'])
    }
  }

  changeLoginState(state: 'username'|'phone') {
    if(state == 'username') {
      this.isLoginByUsername = true
      this.username = ""
      this.password = ""
    } else {
      this.isLoginByUsername = false
      this.phoneNumber = ""
    }
  }

  onKeyup(field: 'username'|'password'|'phone', event: any) {
    this.isFormSubmitted = false
    if(field == 'username') {
      this.username = event.target.value
    } else if (field == 'password') {
      this.password = event.target.value
    } else {
      this.phoneNumber = event.target.value
    }
  }
}
