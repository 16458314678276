export class Sms {
    timestamp: string = ""
    content: string = ""

    constructor(smsResp : SmsResp) {
        this.timestamp = smsResp.timestamp
        this.content = smsResp.content
    }

}

export class SmsResp {
    timestamp: string = ""
    content: string = ""
}

export class ListSmsResp {
    messages: SmsResp[] = []
}

export class SmsReq {
    timestamp: string = ""
    content: string = ""

    constructor(
        timestamp: string,
        conten: string,
    ){
        this.timestamp = timestamp
        this.content = conten
    }
}