import { AppointmentSetting, SidenavOption } from "./common"


export class StaffInfo {
  staffId: string = ""
  fullName: string = ""

  constructor(staffId: string, firstName: string, lastName: string) {
    this.staffId = staffId
    this.fullName = `${firstName} ${lastName}`
  }
}

export class CustomerInfo {
  customerId: string = ""
  fullName: string = ""
  phoneNumber: string = ""

  constructor(user: User) {
    this.customerId = user.id
    this.fullName = `${user.firstName} ${user.lastName}`
    this.phoneNumber = user.phoneNumber
  }
}

export class RegularSchedule {
  day: string = ''
  startTime: string
  endTime: string
  working: boolean = true
  fulltime: boolean = true

  constructor(day: string, startTime: string, endTime: string, working: boolean, fulltime: boolean) {
    this.day = day
    this.startTime = startTime
    this.endTime = endTime
    this.working = working
    this.fulltime = fulltime
  }
}

export class OneTimeSchedule {
  date: string = ''
  startTime: string
  endTime: string
  fullDay: boolean = true

  constructor(date: string, startTime: string, endTime: string, fullDay: boolean) {
    this.date = date
    this.startTime = startTime
    this.endTime = endTime
    this.fullDay = fullDay
  }
}

export class TaskWithAssignedStaff {
  appointmentTaskId: string
  staffInfo: StaffInfo | null

  constructor(appointmentTaskId: string, staffInfo: StaffInfo | null) {
    this.appointmentTaskId = appointmentTaskId
    this.staffInfo = staffInfo
  }
}

export class AssignStaffManuallyReq {
  taskWithAssignedStaff: TaskWithAssignedStaff[]

  constructor(taskWithAssignedStaff: TaskWithAssignedStaff[]) {
    this.taskWithAssignedStaff = taskWithAssignedStaff
  }
}

export class AssignStaffReq {
  staffInfoList: StaffInfo[]

  constructor(staffInfoList: StaffInfo[]) {
    this.staffInfoList = staffInfoList
  }
}

export class AutoAssignAllReq {
  appointmentTaskIds: string[]

  constructor(appointmentTaskIds: string[]) {
    this.appointmentTaskIds = appointmentTaskIds
  }
}
export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER'
}

export class SalonStaff {
  id: string = ""
  firstName: string = ""
  lastName: string = ""
  avaUrl: string = ""
  description: string = ""
  phoneNumber: string = ""
  email: string = ""
  businessId?: string
  storeId: string = ""
  roles: string[] = []
  gender: Gender = Gender.MALE
  regularSchedules: RegularSchedule[] = []
  oneTimeSchedules: OneTimeSchedule[] = []
}



export class SalonStaffReq {
  active: boolean = true
  firstName: string = ""
  lastName: string = ""
  phoneNumber: string = ""
  email: string = ""
  gender: Gender = Gender.MALE
  username: string = ""
  password: string = ""
  roles: string[] | null = []
  avaUrl: string = ""
  description: string = ""
  serviceTypeIds: string[] | null = []
  serviceIds: string[] | null = []
  regularSchedules: RegularSchedule[] = []
  oneTimeSchedules: OneTimeSchedule[] = []

  constructor(
    active: boolean,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    email: string,
    gender: Gender,
    username: string,
    password: string,
    roles: string[] | null,
    avaUrl: string,
    description: string,
    serviceTypeIds: string[] | null,
    serviceIds: string[] | null,
    regularSchedules: RegularSchedule[] = [],
    oneTimeSchedules: OneTimeSchedule[]
  ) {
    this.active = active
    this.firstName = firstName
    this.lastName = lastName
    this.phoneNumber = phoneNumber
    this.email = email
    this.gender = gender
    this.username = username
    this.password = password
    this.roles = roles
    this.avaUrl = avaUrl
    this.description = description
    this.serviceTypeIds = serviceTypeIds
    this.serviceIds = serviceIds
    this.regularSchedules = regularSchedules
    this.oneTimeSchedules = oneTimeSchedules
  }
}

export class SalonStaffsRes {
  salonStaffs: SalonStaff[] = []
}

export class SalonStaffRes {
  id: string = ""
  active: boolean = true
  firstName: string = ""
  lastName: string = ""
  phoneNumber: string = ""
  email: string = ""
  gender: Gender = Gender.MALE
  username: string = ""
  password: string = ""
  storeId: string = ""
  roles: string[] = []
  avaUrl: string = ""
  description: string = ""
  regularSchedules: RegularSchedule[] = []
  oneTimeSchedules: OneTimeSchedule[] = []
}

export class SalonStaffServicesRelationshipResp {
  staffId: string = ""
  serviceTypeIds: string[] = []
  serviceIds: string[] = []
}

export class StaffServiceRelationshipListResp {
  data: SalonStaffServicesRelationshipResp[] = []
}

export class StaffWithStatus {
  staffInfo: StaffInfo
  numOfTurn: number
  currentServiceStarted: string

  constructor(staffInfo: StaffInfo, numOfTurn: number, currentServiceStarted: string) {
    this.staffInfo = staffInfo
    this.numOfTurn = numOfTurn
    this.currentServiceStarted = currentServiceStarted
  }
}

export class SegmentStatus {
  index!: number
  status: boolean = false
  appointmentTaskId: string | null = null
  height: number
  backgroundColor: string
  fontColor: string

  constructor(
    index: number,
    status: boolean,
    appointmentTaskId: string | null,
    height: number,
    backgroundColor: string,
    fontColor: string
  ) {
    this.index = index
    this.status = status
    this.appointmentTaskId = appointmentTaskId
    this.height = height
    this.backgroundColor = backgroundColor
    this.fontColor = fontColor
  }
}

export class ServiceType {
  id: string = ""
  name: string = ""
  description = ""
  isActive: boolean = true
  ordinalIdx: number = 0

  constructor(
    id: string,
    name: string,
    description: string,
    isActive: boolean,
    orderIdx: number
  ) {
    this.id = id
    this.name = name
    this.description = description
    this.isActive = isActive
    this.ordinalIdx = orderIdx
  }
}

export class ServiceStaff {
  id: string = ""
  firstName: string = ""
  lastName: string = ""
}

export interface User {
  id: string,
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  username: string;
}


export class StoreManagerSetting {
  navigationItems: SidenavOption[] = []
  appointmentSetting: AppointmentSetting | null = null
}


// User
export class UserReq {
  firstName: string = ""
  lastName: string = ""
  phoneNumber: string = ""
  email: string

  constructor(firstName: string, lastName: string, phoneNumber: string, email: string) {
    this.firstName = firstName
    this.lastName = lastName
    this.phoneNumber = phoneNumber
    this.email = email
  }
}

export class UserUpdateReq {
  firstName: string = ""
  lastName: string = ""
  email: string = ""

  constructor(firstName: string, lastName: string, email: string) {
    this.firstName = firstName
    this.lastName = lastName
    this.email = email
  }
}

export enum StaffRole {
  OWNER = 'OWNER',
  SALON_MANAGER = 'SALON_MANAGER',
  SALON_STAFF = 'SALON_STAFF'
}

export class AccessToken {
  token: string = ""
  type: string = ""
  expiresAt: number = 0
}

export class RefreshToken {
  token: string = ""
  type: string = ""
  expiresAt: number = 0
}

export class AuthenticationResp {
  staff!: SalonStaff
  accessToken!: AccessToken
  refreshToken!: RefreshToken
}

export class Customer {
  id: string = ""
  firstName: string = ""
  lastName: string = ""
  gender: 'MALE' | 'FEMALE' | 'NON_BINARY' = 'MALE'
  dateOfBirth: string = ""
  phoneNumber: string = ""
  email: string = ""
  isPhoneVerified: boolean = true
  isEmailVerified: boolean = true
  tier: 'NEW' | 'RISKY' | 'REGULAR' | 'VIP' = 'NEW'
  lastVisited: string = ""
  totalSpent: number = 0
  reward: number = 0
}

export class CustomerLastVisited {
  id: string = ""
  date: string = ""
  serviceName: string = ""
  totalSpent: number = 0
  rating: number | null = null
}

