<div class = "nav">
        <button class = "log-out-button" (click) = "logOut()">
            <img class = "logout" src = "./assets/dashboard/logout.png" alt="">
        </button>
    </div>
<main class = "body">
    <img class = "background-image" src="./assets/dashboard/background.jpg" alt="">
    <div class="container">
        <div class="customer">
            <div class="search-bar">
                <button class="search-button">
                    <img class="search-img" src="./assets/dashboard/search.png" alt="">
                </button>
                <input class="search-input" type="text" 
                        [(ngModel)]="searchText" 
                        placeholder="Search by name or phone number"
                        (ngModelChange)="filteredCustomers($event)">
            </div>
            <div class="list-customer" #customerList>   
                <div class="sub-customer"  *ngFor="let customer of displayedCustomers" (click) = "setInfor(customer.phoneNumber)" 
                    [ngClass]="{'active': customer.phoneNumber == phoneNumber}">    

                    <img *ngIf="customer.gender == 'female' " src="./assets/dashboard/female.jpeg" alt="">

                    <img *ngIf="customer.gender == 'male' " src="./assets/dashboard/male.jpeg" alt="">

                    <img *ngIf="customer.gender != 'male' && customer.gender != 'female' " src="./assets/dashboard/cat.jpeg" alt="">
                    <div class="info">
                        <h1 class="name">{{customer.firstName}} {{customer.lastName}}</h1>
                        <p class="phoneNumber">+1 {{formatDisplayedPhoneNumber(customer.phoneNumber)}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="device">
            <img class="iphone" src="./assets/dashboard/QA PHONE.png" alt="">
            <div class="background"></div>
            <div class="message" >
                <div class="sub-message" *ngFor="let message of listSms">
                    <div class="time">
                        <p>{{formatTimestamp(message.timestamp)}}</p>
                    </div>
                    <div class="message-bubble">
                        <pre>{{message.content}}</pre>
                    </div>
                </div>
            </div>

        </div>
        <div class="information">
            <div class="customer-infor">
                <div class="name item">
                    <h1 class="name">{{name}}</h1>
                </div>
                <div class="phone-number item">
                    <img src="./assets/dashboard/telephone.png" alt="">
                    <p>{{phoneNumber}}</p>
                    <img id="copy" src="./assets/dashboard/copy.png" (click)="copyPhoneNumber()">
                </div>
                <div class="birthday item">
                    <img src="./assets/dashboard/birthday-cake.png" alt="">
                    <p>{{birthday}}</p>
                </div>
                <div class="gender item">
                    <img src="./assets/dashboard/gender.png" alt="">
                    <p>{{gender}}</p>
                </div>
                <div class="job item">
                    <img src="./assets/dashboard/job-seeker.png" alt="">
                    <p>{{job}}</p>
                </div>
            </div>
        </div>
    </div>
    
</main>


