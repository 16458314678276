import { Injectable } from '@angular/core';
import { SalonStaff } from '../models/store';
import { environment } from '../../environments/environment';

// import {Appointment, SalonStaff} from "../models/store";

const USER_KEY = environment.userKey
const ACCESS_TOKEN_KEY = "ACCESS_TOKEN";
const EDITING_APPOINTMENT = "EDITING_APPOINTMENT";

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  clean(): void {
    localStorage.clear();
  }

  public saveKey(tokenObject: any): void {
    localStorage.removeItem(ACCESS_TOKEN_KEY)
    localStorage.setItem(ACCESS_TOKEN_KEY, JSON.stringify(tokenObject))
  }


  public saveStaff(staff: any): void {
    localStorage.removeItem(USER_KEY);
    localStorage.setItem(USER_KEY, JSON.stringify(staff));
  }

  public getKey(): any {
    const key = localStorage.getItem(ACCESS_TOKEN_KEY);
    return key ? key : ""
  }

  public getStaff(): SalonStaff|null {
    const user = localStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }
    return null;
  }

  public isLoggedIn(): boolean {
    const user = localStorage.getItem(USER_KEY);
    return !!user;
  }

  // public getEditingAppointment(): Appointment | null {
  //   const editingAppointment = localStorage.getItem(EDITING_APPOINTMENT)
  //   if (editingAppointment) return JSON.parse(editingAppointment)
  //   else return null
  // }

  // public saveEditingAppointment(appointment: Appointment): void {
  //   localStorage.removeItem(EDITING_APPOINTMENT);
  //   localStorage.setItem(EDITING_APPOINTMENT, JSON.stringify(appointment));
  // }

  public clearEditingAppointmentData(): void {
    localStorage.removeItem(EDITING_APPOINTMENT);
  }
}
