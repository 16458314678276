import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, catchError, of, tap } from 'rxjs';
import { ListSmsResp } from '../models/sms';

@Injectable({
  providedIn: 'root'
})
export class SmsService {
  basedUrl: String = environment.baseUrl
  constructor(
    private readonly http: HttpClient
  ) { }

  getSmsByPhoneNumber(businessId: string, phoneNumber : string) : Observable<ListSmsResp> {
    let headers = new HttpHeaders();
    headers = headers.set('businessId', businessId)
    return this.http.get<ListSmsResp>(this.basedUrl + `/mercury/v1/sms?phoneNumber=${phoneNumber}`, {headers: headers}).pipe(
      tap((d: any) => {
        console.log('getSmsByPhoneNumber', d)
      }),
      catchError(this.handleError<ListSmsResp>("getSmsByPhoneNumber"))
    )
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  
}
