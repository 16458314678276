import {Injectable} from "@angular/core";
import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode
} from "@angular/common/http";
import {catchError, Observable, switchMap, tap, throwError} from "rxjs";
import {StorageService} from "../services/storage.service";
import {Router} from "@angular/router";
import {CookieService} from "ngx-cookie-service";
import {AUTH_ACCESS_TOKEN_KEY, AUTH_REFRESH_TOKEN_KEY} from "./token-caching.interceptor";
import { AuthService } from "../services/auth.service";

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private storageService: StorageService,
    private cookieService: CookieService,
    private authService: AuthService
  ) {
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(err => {
        if ([HttpStatusCode.Unauthorized, HttpStatusCode.Forbidden].includes(err.status) && this.storageService.getStaff()) {
          this.handle401Error(req, next)
        }

        const error = err.error?.message || err.statusText;
        return throwError(() => error);
      })
    )
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    this.authService.refreshToken().pipe(
      switchMap(authenticationResp => {
        return next.handle(this.addTokenHeader(req))
      }),
      catchError(err => {
        this.authService.logOut()
        this.router.navigate(['/login']).then(() => {
          window.location.reload();
        })
        const error = err.error?.message || err.statusText;
        return throwError(() => error);
      })
    ).subscribe()
  }

  private addTokenHeader(req: HttpRequest<any>): HttpRequest<any> {
    req = req.clone({
      setHeaders: {
        "access_token": `${JSON.parse(this.cookieService.get(AUTH_ACCESS_TOKEN_KEY)).token}`
      }
    })
    return req
  }
}

export const httpErrorInterceptorProvider = [
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
];
