import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { MatIconModule } from '@angular/material/icon';
import { Subscription, forkJoin } from 'rxjs';
import { AbstractControl, FormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { CustomerService } from '../../services/customer.service';
import { MatTableDataSource } from '@angular/material/table';
import { StorageService } from '../../services/storage.service';
import { Customer, CustomerResp, Gender } from '../../models/customer';
import { SalonStaff } from '../../models/store';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { Sms, SmsResp } from '../../models/sms';
import { SmsService } from '../../services/sms.service';

export function valueInCouponTypeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.get('value');
    const couponType = control.get('type');
    return (couponType?.value == '%' && value?.value > 100) ? {valueWithCouponTypeErr: true} : null;
  }
}

export function notBeforeNowValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return (control.value < new Date()) ? {notBeforeNowErr: true} : null;
  }
}

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [CommonModule, RouterOutlet, MatIconModule, FormsModule, MatSelectModule, NgxMatSelectSearchModule],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnInit{

  @ViewChild('customerList', { static: false }) customerList!: ElementRef;
  staff: SalonStaff | null = null
  searchText : string = "";
  data: undefined | Customer[];
  email : string = "simonhor@gmail.com"
  customerId = ""
  name : string = ""
  gender : string | null = null
  phoneNumber : string = ""
  birthday : string = ""
  job : string = ""
  dob : string = ""

  sub!: Subscription
  businessId!: string
  customerDetail!: CustomerResp
  customers: Customer[] = [];
  displayedCustomers: Customer[] = [];
  listCustomers: Customer[] = [];
  dataSource!: MatTableDataSource<Customer>;
  avtURL: string = "./assets/dashboard/female.jpeg"
  displayedLastVisitedColumns = ['date', 'serviceName', 'totalSpent', 'rating'];
  isLoading = false;
  isLoadingNextCustomers = false
  isUpdateLoading = false
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  protected readonly Array = Array;
  listSms: Sms[] = []

  constructor(
    public router: Router,
    private authService: AuthService,
    private storageService: StorageService,
    private readonly customerService: CustomerService,
    private readonly smsService: SmsService,
  ){

  }

  ngOnInit(): void {
    this.staff = this.storageService.getStaff()
    if (this.staff == null) {
      this.logOut()
    }
    this.businessId = this.storageService.getStaff()?.businessId || ""
    this.sub = forkJoin({
      customers: this.customerService.getCustomers(this.businessId)
      
    }).subscribe(
      value => {
        this.customers = value.customers.data.map((ele: CustomerResp) => new Customer(ele));
        this.dataSource = new MatTableDataSource<Customer>(this.customers);
        this.isLoading = false;
        console.log("customer " + this.customers)
        this.displayedCustomers = this.customers;
        this.listCustomers = this.customers;
        this.setInfor(this.customers[0].phoneNumber);
      }
    );
  }

  logOut(): void {
    this.router.navigate(['/']).then(() => {
      window.location.reload();
    })
    this.authService.logOut()
  }

  formatDisplayedPhoneNumber(phoneNumber: string): string {
    return phoneNumber.replace(/^(\d{3})(\d{3})(\d{4}).*/, '($1) $2-$3')
  }

  setInfor(phoneNumber : string) : void{
    console.log(phoneNumber);
    this.sub = forkJoin({
      customer: this.customerService.getCustomerByPhoneNumber(this.businessId, phoneNumber)
    }).subscribe(
        value => {
          this.customerId = value.customer.data[0].id
          this.name = value.customer.data[0].firstName.toLowerCase() + " " + value.customer.data[0].lastName.toLowerCase();
          this.phoneNumber = value.customer.data[0].phoneNumber;
          this.gender = value.customer.data[0].gender;
          this.birthday = value.customer.data[0].dob;
          this.job = value.customer.data[0].profession;
          console.log("value " + value)
        }
    )

    this.sub = forkJoin({
      sms : this.smsService.getSmsByPhoneNumber(this.businessId, phoneNumber)
    }).subscribe(
        value => {
          this.listSms = value.sms.messages.map((ele: SmsResp) => new Sms(ele));
          console.log("message " + this.listSms)
        }
    )
  }

  ngAfterViewInit() {
    this.customerList.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
  }

  getNextCustomers(){
    this.sub = forkJoin({
      customers: this.customerService.getNextCustomers(this.businessId)
      
    }).subscribe(
      value => {
        this.customers = this.customers.concat(value.customers.data.map((ele: CustomerResp) => new Customer(ele)));
        this.dataSource = new MatTableDataSource<Customer>(this.customers);
        this.isLoading = false;
        console.log("next-customer " + this.customers)
        this.displayedCustomers = this.customers
      }
    );

  }
  loadCustomers() {
    if (this.isLoadingNextCustomers) return;
    this.isLoadingNextCustomers = true;
    if(this.customerService.checkNextKey())
    this.getNextCustomers()
    this.isLoadingNextCustomers = false;
  }

  onScroll() {
    const { scrollTop, scrollHeight, clientHeight } = this.customerList.nativeElement;
    if (scrollTop + clientHeight >= scrollHeight) {
      this.loadCustomers();
    }
  }
  
  filteredCustomers(event: Event) {
    if (this.searchText.trim() === '') {
      this.displayedCustomers = this.customers
    } else {

      if(this.searchText.length === 11){
          this.sub = this.customerService.getCustomerByPhoneNumber(this.businessId, this.searchText)
          .subscribe(
            value => {
              value.data[0].firstName = value.data[0].firstName.toLowerCase()
              value.data[0].lastName = value.data[0].lastName.toLowerCase()
              this.displayedCustomers = value.data;
              console.log("value " + value)
            }
        )
      }
      this.displayedCustomers = this.displayedCustomers.filter(customer => {
            const fullName = `${customer.firstName} ${customer.lastName}`;
            return (
              fullName.toLowerCase().includes(this.searchText.toLowerCase()) ||
              customer.id.includes(this.searchText)
            );
      });
    }
  }

  formatTimestamp(timestamp: string): string {
    const date = new Date(timestamp);
    const options: Intl.DateTimeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit',
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour12: false
    };
  
    const time = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });
    const formattedDate = date.toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' });

    return `${time} ${formattedDate}`;
  }

  copyPhoneNumber(){
    navigator.clipboard.writeText(this.phoneNumber).then(() => {
      console.log('Sao chép thành công:', this.phoneNumber);
    }).catch(err => {
      console.error('Không thể sao chép:', err);
    });
  }
}

