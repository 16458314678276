import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { TokenRefreshInterceptor } from './http-interceptors/token-refresh.interceptor';
import { TokenCachingInterceptor } from './http-interceptors/token-caching.interceptor';
import { ErrorInterceptor } from './http-interceptors/error.interceptor';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes), 
    
    provideHttpClient(withInterceptorsFromDi()),  
    {
        provide:HTTP_INTERCEPTORS,
        useClass: TokenRefreshInterceptor,
        multi:true
    },
    {
      provide:HTTP_INTERCEPTORS,
      useClass: TokenCachingInterceptor,
      multi:true
  },
  {
    provide:HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi:true
}, provideAnimationsAsync()
  ]
};
