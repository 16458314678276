import {Injectable} from "@angular/core";
import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import {map, Observable} from "rxjs";
import {StorageService} from "../services/storage.service";
import {environment} from "../../environments/environment";
import {CookieService} from "ngx-cookie-service";

export const AUTH_ACCESS_TOKEN_KEY = "AUTH_ACCESS_TOKEN"
export const AUTH_REFRESH_TOKEN_KEY = "AUTH_REFRESH_TOKEN"

@Injectable({
  providedIn: 'root'
})
export class TokenCachingInterceptor implements HttpInterceptor {
  constructor(
    private storageService: StorageService,
    private cookieService: CookieService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('/signIn') || req.url.includes('/refreshToken')) {
      return next.handle(req).pipe(
        map((response: any) => {
          if (response instanceof HttpResponse && response.ok) {
            response.body.accessToken.expiresAt *= 1000
            response.body.refreshToken.expiresAt *= 1000
            this.cookieService.set(AUTH_ACCESS_TOKEN_KEY, JSON.stringify(response.body.accessToken), new Date(response.body.accessToken.expiresAt), '/', undefined, true);
            this.cookieService.set(AUTH_REFRESH_TOKEN_KEY, JSON.stringify(response.body.refreshToken), new Date(response.body.refreshToken.expiresAt), '/', undefined, true);
          }
          return response
        })
      )
    }

    if (req.url.indexOf('/logout') > -1) {
      this.cookieService.deleteAll();
      return next.handle(req);
    }

    const user = this.storageService.getStaff();
    const isLoggedIn = user && this.cookieService.get(AUTH_ACCESS_TOKEN_KEY);
    const isApiUrl = req.url.startsWith(environment.baseUrl);
    if (isLoggedIn && isApiUrl) {
      req = req.clone({
        setHeaders: {
          "access_token": `${JSON.parse(this.cookieService.get(AUTH_ACCESS_TOKEN_KEY)).token}`
        }
      })
    }
    return next.handle(req);
  }
}

export const httpCachingInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: TokenCachingInterceptor, multi: true }
]
