<div class="login">
  <ng-container *ngIf="!isLoginByUsername; then loginByPhoneNumber; else loginByUsername">

  </ng-container>
</div>

<ng-template #loginByUsername>
  <mat-card class="username-login-content">
    <div class="login-title"> Login</div>
    <form [formGroup]="loginForm" (ngSubmit)="login('username')">
      <div class="login-field">
        <div class="username-input-field"
             [ngClass]="{'invalid-input': isFormSubmitted && loginForm.controls['username'].errors?.['required']}">
          <input class="input"
                 placeholder="Username"
                 (keyup)="onKeyup('username', $event)"
                 formControlName="username">
        </div>
        <span class="text-danger" *ngIf="isFormSubmitted && loginForm.controls['username'].errors?.['required']">Field is <strong>required</strong>.</span>
      </div>
      <div class="login-field">
        <div class="password-input-field"
             [ngClass]="{'invalid-input': isFormSubmitted && loginForm.controls['password'].errors?.['required']}">
          <input class="input"
                 [type]="showPwd? 'text' : 'password'"
                 (keyup)="onKeyup('password', $event)"
                 placeholder="Password"
                 formControlName="password">
          <mat-icon class="visibility-pwd-btn"
                    (click)="showPwd = !showPwd">{{!showPwd ? 'visibility_off' : 'visibility'}}</mat-icon>
        </div>
        <span class="text-danger" *ngIf="isFormSubmitted && loginForm.controls['password'].errors?.['required']">Field is <strong>required</strong>.</span>
        <span class="text-danger" *ngIf="isFormSubmitted && loginStatus == false">Username or password is not correct.</span>
      </div>
      <button type="submit" class="signin-btn">
        Sign in
      </button>
    </form>
    <div (click)="changeLoginState('phone')" class="login-option">
      Sign in with phone number
    </div>
  </mat-card>
</ng-template>

<ng-template #loginByPhoneNumber>
  <mat-card class="phone-login-container">
    <div class="login-title"> Login</div>
    <div class="phone-input-field">
      <input class="input"
             type="number"
             placeholder="Enter your phone number"
             (keyup)="onKeyup('phone', $event)">
    </div>
    <button (click)="login('phone')" class="signin-btn">
      Verify
    </button>
    <div (click)="changeLoginState('username')" class="login-option">
      Sign in with username
    </div>
  </mat-card>
</ng-template>
