import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {catchError, Observable, of, tap} from "rxjs";
import {CookieService} from "ngx-cookie-service";
import {AUTH_ACCESS_TOKEN_KEY, AUTH_REFRESH_TOKEN_KEY} from "../http-interceptors/token-caching.interceptor";
import {StorageService} from "./storage.service";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationResp } from '../models/store';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  basedUrl = environment.baseUrl;
  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private cookieService: CookieService
  ) { }

  logIn(username: string, password: string): Observable<AuthenticationResp | null> {
    let headers = new HttpHeaders()
    headers = headers.set('Authorization', `Basic ${btoa(`${username}:${password}`)}`)
    return this.http.post<AuthenticationResp>(this.basedUrl + `/identity/v1/auth/staffs/signIn`, {}, { headers: headers }).pipe(
      tap(authenticationResp => authenticationResp),
      catchError(this.handleError<AuthenticationResp | null>("logIn", null))
    )

  }

  refreshToken(): Observable<AuthenticationResp | null> {
    let headers = new HttpHeaders()
    headers = headers.set("access_token", JSON.parse(this.cookieService.get(AUTH_REFRESH_TOKEN_KEY)).token)
    return this.http.post<AuthenticationResp>(this.basedUrl + `/identity/v1/auth/staffs/refreshToken`, {}, { headers: headers }).pipe(
      catchError(this.handleError<AuthenticationResp | null>("refreshToken", null))
    )
  }

  logOut(): void {
    this.storageService.clean();
    this.cookieService.deleteAll();
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // Let the app keep running by returning an empty result.
      return of(result as T);
    }
  }
}
