import {
  CustomersResp,
} from "../models/customer";
import {environment} from "../../environments/environment";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, catchError, of, tap } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  basedUrl: String = environment.baseUrl
  nextKey: string|null = null
  constructor(
    private readonly http: HttpClient
  ) { }

  getCustomers(businessId: string): Observable<CustomersResp> {
    let headers = new HttpHeaders();
    headers = headers.set('businessId', businessId)
    return this.http.get(this.basedUrl + `/mercury/v1/dummy-users`, {headers: headers}).pipe(
      tap((d: any) => {
        console.log('getCustomers', d)
        this.nextKey = d.nextKey
        console.log("key " + this.nextKey)
      }),
      catchError(this.handleError<CustomersResp>("getCustomers"))
    
    )
    // return of(MOCK_DATA)
  }

  getNextCustomers(businessId: string): Observable<CustomersResp> {
    let headers = new HttpHeaders();
    headers = headers.set('businessId', businessId)
    return this.http.get(this.basedUrl + `/mercury/v1/dummy-users?nextKey=${this.nextKey}`, {headers: headers}).pipe(
      tap((d: any) => {
        console.log('getnextCustomers', d)
        this.nextKey = d.nextKey

        console.log("nextkey " + this.nextKey)
      }),
      catchError(this.handleError<CustomersResp>("getCustomers"))
    )
    // return of(MOCK_DATA)
  }

  checkNextKey() : boolean{
    if(this.nextKey == null){
      return false
    }
    return true
  }
  getCustomerByPhoneNumber(businessId: string, phoneNumber: string): Observable<CustomersResp> {
    let headers = new HttpHeaders();
    headers = headers.set('businessId', businessId);
    return this.http.get(this.basedUrl + `/mercury/v1/dummy-users?phoneNumber=${phoneNumber}`, {headers: headers}).pipe(
      tap((d: any) => {
        console.log('getCustomerByPhoneNumber', d)
      }),
      catchError(this.handleError<CustomersResp>("getCustomers"))
    )
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
