import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {filter, map, of, tap} from "rxjs";
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  verifyCode = "012345"
  constructor(
    // private http: HttpClient,
              private storageSrv: StorageService
            ) {
  }

  getVerifyCode() {
    return of(this.verifyCode)
  }
}
