import {Injectable} from "@angular/core";
import {HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {catchError, lastValueFrom, map, Observable, switchMap, tap, throwError} from "rxjs";
import {AccessToken, AuthenticationResp} from "../models/store";
import {CookieService} from "ngx-cookie-service";
import {AUTH_ACCESS_TOKEN_KEY, AUTH_REFRESH_TOKEN_KEY} from "./token-caching.interceptor";
import {AuthService} from "../services/auth.service";
import {environment} from "../../environments/environment";
import {StorageService} from "../services/storage.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class TokenRefreshInterceptor implements HttpInterceptor {
  private isAlreadyRoute = false
  private tokenRefreshInProgress = false;
  refreshTokenExpMinutes = environment.refreshTokenExpMinutes
  constructor(
    private router: Router,
    private cookieService: CookieService,
    private storageService: StorageService,
    private authService: AuthService
  ) {
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('/signIn')) {
      return next.handle(req)
    }
    const user = this.storageService.getStaff();
    const isLoggedIn = user != null && this.cookieService.get(AUTH_ACCESS_TOKEN_KEY).length > 0;
    const isApiUrl = req.url.startsWith(environment.baseUrl);
    if (this.cookieService.get(AUTH_REFRESH_TOKEN_KEY).length > 0) {
      if (isLoggedIn && isApiUrl) {
        const accessToken: AccessToken = JSON.parse(this.cookieService.get(AUTH_ACCESS_TOKEN_KEY));
        if (this.shouldRefreshToken(accessToken) && !this.tokenRefreshInProgress) {
          this.tokenRefreshInProgress = true
          this.authService.refreshToken().subscribe(
            authenticationRes => {
              this.tokenRefreshInProgress = false
            }
          )
        }
        return next.handle(req)
      } else {
        return next.handle(req).pipe(
          tap(event => {
            if (!this.tokenRefreshInProgress) {
              this.tokenRefreshInProgress = true
              this.authService.refreshToken().subscribe(
                authenticationResp => {
                  this.tokenRefreshInProgress = false
                  window.location.reload()
                }
              )
            }
          })
        )
      }
    } else {
      this.logOut()
      return next.handle(req)
    }
  }

  private shouldRefreshToken(accessToken: AccessToken): boolean {
    return (accessToken.expiresAt - new Date().getTime() <= (this.refreshTokenExpMinutes)*60*1000);
  }

  private logOut(): void {
    this.authService.logOut()
    this.router.navigate(['/login']).then(() => {
      window.location.reload();
    })
  }
}

export const httpTokenRefreshInterceptorProvider = [
  { provide: HTTP_INTERCEPTORS, useClass: TokenRefreshInterceptor, multi: true }
];
