export enum Gender {
  MALE = 'male',
  FEMALE = 'female',
  OTHERS = 'others'
}

export class Customer {
  id: string = ""
  firstName: string = ""
  lastName: string = ""
  gender: Gender = Gender.MALE
  phoneNumber: string = ""
  profession: string = ""
  dob: string = ""

  constructor(customerResp: CustomerResp) {
    this.id = customerResp.id
    this.firstName = customerResp.firstName.toLowerCase()
    this.lastName = customerResp.lastName.toLowerCase()
    this.gender = customerResp.gender
    this.phoneNumber = customerResp.phoneNumber
    this.profession = customerResp.profession
    this.dob = customerResp.dob
  }

}

export class CustomerReq {
  firstName: string = ""
  lastName: string = ""
  gender: string = ""
  phoneNumber: string = ""
  profession: string = ""
  dob: string = ""

  constructor(
    firstName: string,
    lastName: string,
    gender: string,
    phoneNumber: string,
    profession: string,
    dob: string,
  ) {
    this.firstName = firstName.toLowerCase()
    this.lastName = lastName.toLowerCase()
    this.gender = gender
    this.phoneNumber= phoneNumber
    this.profession = profession
    this.dob = dob
  }
}

export class CustomersResp {
  data: CustomerResp[] = []
  nextKey: string = ""
}

export class CustomerResp {
  id: string = ""
  firstName: string = ""
  lastName: string = ""
  gender: Gender = Gender.MALE
  phoneNumber: string = ""
  profession: string = ""
  dob: string = ""
}

export class RewardHistory {
  id: string = ""
  point: number = 0
  redeemDate: number = 0
}

export class CustomerRespByPhone {
  data : CustomerResp|null = null
  nextKey : string|null = ""
}